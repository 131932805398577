import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors } from "../../assets/colors";

const ButtonContainer = styled.div`
  & {
    z-index: 99;
    height: 4vmax;
    width: 4vmax;
    border-radius: 999px;
    display: flex;
    padding-left: 0.55vmax;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 2.7vmax;
    background-color: ${colors.white};
    overflow: hidden;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: solid 0.05em ${colors.black};
    cursor: pointer;
  }
  transition: width 0.5s 0.25s, background-color 0.25s;
  &::before {
    content: "";
    position: absolute;
    left: 0%;
    width: 5vmax;
    height: 50%;
    z-index: 100;
  }
  &:hover::before{
      width:19vmax;
  }
  &:hover {
    width: 19vmax;
    
  }
`;

const TextContainer = styled.div`
  margin-left: 1vmax;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 2.6vmax;
`;

const TitleText = styled.h1`
  font-size: 1.4vmax;
  font-family: futura-pt, sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1.2;
  width: 20vmax;
  text-align: left;
  color: #1e1e1e;
`;

function MinButton(props) {

  return (
    <Link to={props.link} style={{textDecoration: "none"}}>
      <ButtonContainer
        style={{
          display: props.visible ? "none" : "flex",
        }}
      >
        <IconContainer>
          <props.icon color={colors.black} />
        </IconContainer>
        <TextContainer>
          <TitleText style={{ color: colors.black }}>
            {props.text}
          </TitleText>
        </TextContainer>
      </ButtonContainer>
    </Link>
  );
}

export default MinButton;