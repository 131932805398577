import tipx from './logos/tipx.png';
import tuktuk from './logos/tuktuk.png';
import cornerstone from './logos/cornerstone.png';
import root from './logos/root.png';
import alois from './logos/alois.png';
import gazelle from './logos/gazelle.png';
import populus from './logos/populus.png';
import scraps from './logos/scraps.png';
import cacao from './logos/cacao.png';
import locol from './logos/locol.png';
import sensibed from './logos/sensibed.png';
import flightdeck from './logos/flightdeck.png';
import rytestart from './logos/rytestart.png';
import omnisense from './logos/omnisense.png';
import thrive from './logos/thrive.png';
import memary from './logos/memary.png';
import activeprophylaxis from './logos/activeprophylaxis.png';

// TODO missing Three0
// TODO add founders for recent cohort

const info = [
    {
        name: "Rytestart",
        logo: rytestart,
        semester: 'Spring 2024',
    },
    {
        name: 'Omnisense Surgical',
        logo: omnisense,
        semester: 'Spring 2024',
    },
    {
        name: 'Thrive Education',
        logo: thrive,
        semester: 'Spring 2024',
    },
    {
        name: 'MemARy Labs',
        logo: memary,
        semester: 'Spring 2024',
    },
    {
        name: 'Active Prophylaxis',
        logo: activeprophylaxis,
        semester: 'Spring 2024',
    },
    {
        name: 'Scraps',
        logo: scraps,
        semester: 'Fall 2023',
        description: 'Scraps is a marketplace for artists to buy and sell music production assets.',
        founders: [
            {
                name: 'Henry Quillin',
                title: "CTO",
                linkedin: 'https://www.linkedin.com/in/henryquillin/'
            },
            {
                name: 'Alejo Orvananos',
                title: "Co-founder",
                linkedin: 'https://www.linkedin.com/in/alejoorvananos/'
            }
        ]
    },
    {
        name: 'cacao.tax',
        logo: cacao,
        semester: 'Fall 2023',
        description: 'Duty Free Marketplace for International Travelers',
        founders: [
            {
                name: "José Yrigoyen",
                title: "Founder",
                linkedin: "https://www.linkedin.com/in/jmyrigoyen/"
            }
        ]
    },
    {
        name: 'Locol',
        logo: locol,
        semester: 'Fall 2023',
        description: 'Locol is the freelance marketplace connecting small businesses with student freelancers who can complete digital marketing tasks for them.',
        founders: [
            {
                name: "Mateo Delaguila",
                title: "Founder & CEO",
                linkedin: "https://www.linkedin.com/in/mateodelaguila/"
            }
        ]
    },
    {
        name: 'Sensibed Tech',
        logo: sensibed,
        semester: 'Fall 2023',
        description: 'We aim to deliver a reliable and efficient solution that aids in the early detection and monitoring of pressure ulcers, ultimately improving patient outcomes and reducing healthcare costs. This will revolutionize the pressure ulcer industry by improving the relationship between patients and nurses.',
        founders: [
            {
                name: "Srikar Sadhu",
                title: "Co-founder & CEO",
                linkedin: "https://www.linkedin.com/in/srikarsadhu/"
            },
            {
                name: "Samantha Preston",
                title: "Co-founder & CTO",
                linkedin: "https://www.linkedin.com/in/samantha-prestone/"
            }
        ]
    },
    {
        name: 'Flightdeck',
        logo: flightdeck,
        semester: 'Fall 2023',
        description: 'Flightdeck superpowers partnerships by providing a central platform for partners to gain visibility to their communications and reduces the time spent managing. It\'s a community of founders and partners who want to 10x their companies by forming more committed and valuable partnerships.',
        founders: [
            {
                name: "Yash Aggarwal",
                title: "Co-founder",
                linkedin: "https://www.linkedin.com/in/yash-aggarwal-/"
            },
            {
                name: "Aditya Bhatia",
                title: "Co-founder",
                linkedin: "https://www.linkedin.com/in/adityabhatia18/"
            },
            {
                name: "Sreesaketh Grandhe",
                title: "Co-founder",
                linkedin: "https://www.linkedin.com/in/sree-grandhe/"
            }
        ]
    },
    {
        name: 'Gazelle',
        logo: gazelle,
        semester: 'Fall 2022',
        description: 'Gazelle empowers ranchers to make data-driven decisions through smart livestock/land management and a carbon offset trading platform, ensuring their economic longevity and land’s ecological future.',
        founders: [
            {
                name: 'Siddharth Thakur',
                title: 'Co-Founder',
                linkedin: 'https://www.linkedin.com/in/siddharth3thakur/'
            },
            {
                name: 'Amod Daherkar',
                title: 'Co-Founder',
                linkedin: 'https://www.linkedin.com/in/amod-daherkar/'
            },
            {
                name: 'Mihir Bendre',
                title: 'Co-Founder',
                linkedin: 'https://www.linkedin.com/in/mihir-bendre/'
            }
        ]
    },
    {
        name: 'Populus',
        logo: populus,
        semester: 'Fall 2022',
        description: 'Populus is a game currently available in the app store which combines the worlds of fantasy sports and stock market trading to create a unique experience for the user. Through Populus, you can invest in sports players through a stock-like interface, but without betting real money. Imagine being able to buy a stock of Lebron James and watch his stock climb with every dunk.',
        founders: [
            {
                name: 'Himanshu Biradar',
                title: 'Co-Founder',
                linkedin: 'https://www.linkedin.com/in/himanshu-biradar-7a4b601b5/'
            },
            {
                name: 'Aditya Bhatia',
                title: 'Co-Founder',
                linkedin: 'https://www.linkedin.com/in/adityabhatia69/'
            }
        ]
    },
    {
        name: 'Tuktuk',
        logo: tuktuk,
        semester: 'Spring 2022 & Fall 2022',
        description: 'Tuktuk is a super affordable, safe, and convenient alternative to the likes of Uber and Lyft. Tuktuk redefines carpooling through their platform which provides convenient and scheduled carpooling between hubs or Austin hotspots which cover the most popular student travel routes.',
        founders: [
            {
                name: 'Sujoy Purkayastha',
                title: 'Co-Founder',
                linkedin: 'https://www.linkedin.com/in/sujoypurkayastha/',
            },
            {
                name: 'Akif Abidi',
                title: 'Co-Founder',
                linkedin: 'https://www.linkedin.com/in/akif-abidi/',
            }
        ]
    },
    {
        name: 'Cornerstone',
        logo: cornerstone,
        semester: 'Spring 2022 & Fall 2022',
        description: 'Cornerstone is a B2B platform that eliminates the use of overpriced and underused software, centralizes every aspect of a given project, and facilitates the relationship between contractors, their clients, architects, and subs.',
        founders: [
            {
                name: 'David Willson',
                title: 'Co-Founder',
                linkedin: 'https://www.linkedin.com/in/david--willson/',
            },
            {
                name: 'Kalen Bhakta',
                title: 'Co-Founder',
                linkedin: 'https://www.linkedin.com/in/kalenbhakta/'
            },
            {
                name: 'Ishaan Grewal',
                title: 'Co-Founder',
                linkedin: 'https://www.linkedin.com/in/ishaan-grewal-772a3b1a9/',
            },
            {
                name: 'Aniruddh Sriram',
                title: 'Co-Founder',
                linkedin: 'https://www.linkedin.com/in/aniruddh-sriram/'
            }
        ]
    },
    {
        name: 'HiFive (TipX)',
        logo: tipx,
        semester: 'Spring 2022',
        description: 'TipX is a review, rating, and tipping web application that enables employees to receive recognition on their customer service performance while helping them earn contactless tips through QR code technology.',
        founders: [
            {
                name: 'Numan Dharani',
                title: 'Co-Founder',
                linkedin: 'https://www.linkedin.com/in/numan-dharani/',
            },
            {
                name: 'Aman Dosani',
                title: 'Co-Founder',
                linkedin: 'https://www.linkedin.com/in/amaan-dosani-00306314a/',
            }
        ]
    },
    {
        name: 'Root',
        logo: root,
        semester: 'Spring 2022',
        description: 'Root was started by Dr. Hole whose initial vision was sprouted based on her time serving patients and the "Donut Story" which highlights the need for accurate and available translation. Root seeks to break communication barriers through an accurate, peer-reviewed, voice-first medical translation platform enabling understanding between patients and providers.',
        founders: [
            {
                name: 'Jennifer Hole',
                title: 'Co-Founder & President',
                linkedin: 'https://www.linkedin.com/in/jennifer-hole-dds-mstc-44b154b3/'
            },
            {
                name: 'Peter Beery',
                title: 'Co-Founder & CTO',
                linkedin: 'https://www.linkedin.com/in/peterbeery/'
            },
            {
                name: 'Clay Jeffrey Holberg',
                title: 'Co-Founder & CEO',
                linkedin: 'https://www.linkedin.com/in/clay-h-150a0522/'
            }
        ]
    },
    {
        name: 'Alois',
        logo: alois,
        semester: 'Spring 2022',
        description: 'Alois Software is a digital health platform that hopes to redefine at-home caregiving with an all in one web app with features like a community board that fosters conversations around what motivates caregivers, a learning dashboard which informs caregivers on up to date and credible information, and a marketplace which serves a consolidated hub of services and other resources caretakers can access.',
        founders: [
            {
                name: 'Shravan Ravi',
                title: 'Co-Founder',
                linkedin: 'https://www.linkedin.com/in/shravan-ravi/'
            },
            {
                name: 'Dade Ogunmuyiwa',
                title: 'Co-Founder',
                linkedin: 'https://www.linkedin.com/in/modade/'
            }
        ]
    }
]

export default info;