import styled from "styled-components";
import NextSection from "../general/NextSection";
import { colors } from "../../assets/colors";
import { useSectionCursor } from "../general/Cursor";
import portfolioInfo from "../../assets/portfolioInfo";

const CURR_COMPANIES = ["Cacao Tax", "Scraps", "Rytestart", "Omnisense Surgical", "Thrive Education", "MemARy Labs", "Active Prophylaxis"];
const COMPANIES = portfolioInfo.filter((company) => CURR_COMPANIES.includes(company.name));

const SectionContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: ${colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  position: relative;
  flex-wrap: wrap;
  top: -1px;
  max-width: 100%;
`;

const TextContainer = styled.div`
  margin-left: 10vw;
  margin-right: 10vw;
`;

const TitleText = styled.h1`
  display: block;
  text-align: center;
  font-size: 4vmin;
  font-family: ReadexPro, sans-serif;
  font-weight: 500;
  color: ${colors.red};
  @media only screen and (max-width: 700px) {
    font-size: 6vmin;
  }
  text-transform: uppercase;
`;

const LogoContainer = styled.div`
  display: flex;
  font-size: 3.2vmin;
  font-family: ReadexPro, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  color: ${colors.black};
  line-height: 1.6;
  font-weight: 300;
  @media only screen and (max-width: 700px) {
    font-size: 4vmin;
  }
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const LogoImg = styled.img`
  width: 20vw;
  height: 10vw;
  margin: 1vw;
  margin-top: 4vh;
  object-fit: contain;
  @media only screen and (max-width: 700px) {
    width: 40vw;
  }
`;

const LogoCaption = styled.p`
  font-size: 2vmin;
  margin: 0;
  padding: 0;
  color: ${colors.gray};
  font-weight: 200;
  text-align: center;
  font-style: italic;
`;

const Logo = ({src, name}) => (
  <div>
    <LogoImg src={src} />
    <LogoCaption>{name}</LogoCaption>
  </div>
);

const Spacer = styled.div`
  width: 100%;
  height: 50vh;
`;

const SmallSpacer = styled.div`
  width: 100%;
  height: 5vh;
`;

function Text() {
  const cursorHandlers = useSectionCursor({color: colors.white});
  
  return (
    <SectionContainer {...cursorHandlers}>
      {/* <NextSection ScrollNext={350} ScrollAlready={100} color={colors.white} /> */}
      <TextContainer>
        <TitleText>Current Cohort</TitleText>
        <SmallSpacer />
        <LogoContainer>
          {COMPANIES.map((company, index) => (
            <Logo src={company.logo} name={company.name} />
          ))}
        </LogoContainer>
      </TextContainer>
    </SectionContainer>
  );
}

export default Text;