import styled from "styled-components";
import { colors } from "../../assets/colors";
import { useSectionCursor } from "../general/Cursor";
import NextSection from "../general/NextSection";
import { Link } from "react-router-dom";

const SectionContainer = styled.div`
  width: 100vw;
  min-height: 150vh;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: start;
  flex-wrap: wrap;
  position: sticky;
  top: -1px;
  max-width: 100%;
  @media screen and (max-width: 500px) {
    height: 245vh;
  }
`;

const TextContainer = styled.div`
  padding: 5vh 10vw 10vh 10vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  gap: 20px;

  @media (min-width: 700px) {
    flex-direction: row;
  }
`;

const TitleText = styled.h3`
  font-size: 1.9em;
  padding: 10vh 10vw 0 10vw;
  width: 100%;
  font-family: ReadexPro, sans-serif;
  font-weight: 700;
  text-align: left;
  color: ${colors.red};
  @media only screen and (max-width: 700px) {
    font-size: 5vmin;
  }
`;

const Column = styled.p`
  display: inline-block;
  font-size: 1.1em;
  font-family: ReadexPro, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  flex-basis: auto;
  color: ${colors.black};
  line-height: 1.6;
  font-weight: 300;
  @media only screen and (max-width: 700px) {
    font-size: 4vmin;
  }

  strong {
    font-size: 1.4em;
  }
`;

const Spacer = styled.div`
  width: 100%;
  height: 50vh;
`;

const SmallSpacer = styled.div`
  width: 100%;
  height: 5vh;
`;

function Text() {
  const cursorHandlers = useSectionCursor({ color: colors.black });

  return (
    <SectionContainer {...cursorHandlers}>
      {/* <NextSection ScrollNext={250} ScrollAlready={200} color={colors.black} /> */}
      <TitleText>WHAT TO EXPECT</TitleText>
      <TextContainer>
        <Column>
          <strong>Startups</strong>
          <br />
          <p>
          Get a team of UT Austin students specializing in Software Engineering, Design, Market research, and Growth to help get your project to the next level.
This is a hands-on leadership experience. You will feel what it is like to run a team of employees in the early days of a company. It is your responsibility to schedule meetings with your interns, delegate decision-making, and implement your roadmap. In-person participation every week is required to run your team.
Experienced Entrepreneurs from Austin’s tech scene will provide counsel throughout your time in Momentum.
          </p>
        </Column>
        <Column>
          <strong>Interns</strong>
          <br />
          <p>
          Experience real-world problem-solving not found in your classroom. Show off your technical, artistic, and business skills in an arena of entrepreneurial peers. Sharpen your ability to find hacks in the system; opportunities for innovation and profit.
You will join a student-founded startup. Now don’t discredit ‘student-founded’; these are real companies, with real products and real customers. Momentum startups span a wide range of development stages, from prototyping to generating revenue.
You will hit the ground running, as if you were a new hire in a fast-growing startup.
          </p>
        </Column>
      </TextContainer>
      {/* <Link to="/hidden" style={{margin: "auto", textDecoration: "none"}}>
        <button style={{ padding: '10px 20px', backgroundColor: 'blue', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Learn more</button>
      </Link> */}
    </SectionContainer>   
  );
}

export default Text;
