import styled from "styled-components";
import { colors } from "../../assets/colors";
import {
  useSectionCursor,
  useCursorHandlers,
  CursorContext,
} from "../general/Cursor";
import portfolioInfo from "../../assets/portfolioInfo";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CompaniesInfo from "./InfoBox";

// only get the companies where the semester is not spring 2024
const companies = portfolioInfo.filter(
  (company) => company.semester !== "Spring 2024"
);

const LogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 10vw;
  @media (max-width: 450px) {
    display: grid;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const LogoImg = styled.img`
  width: 10vw;
  height: 10vw;
  margin: 1vw;
  object-fit: contain;
  @media only screen and (max-width: 700px) {
    width: 40vw;
  }
`;

const LogoCaption = styled.p`
  font-size: 2vmin;
  margin: 0;
  padding: 0;
  color: ${colors.gray};
  font-weight: 200;
  text-align: center;
  font-style: italic;
`;

const CompanySemester = styled.h2`
  font-family: "ReadexPro", sans-serif;
  color: ${colors.red};
  font-size: 1.2em;
  font-weight: 500;
  margin: 2vw 0;
  text-align: center;
`;

const SectionContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${colors.black};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: -1px;
  max-width: 100%;
`;

const TitleText = styled.h1`
  display: block;
  text-align: center;
  font-size: 4vmin;
  font-family: ReadexPro, sans-serif;
  font-weight: 500;
  color: ${colors.red};
  @media only screen and (max-width: 700px) {
    font-size: 6vmin;
  }
  text-transform: uppercase;
`;

const L = styled.div`
  border-radius: 10px;
  &:hover {
    color: ${colors.red};
    background-color: ${colors.gray}33;
  }
  transition: all 0.3s;
  margin: 10px;
`;

const Logo = ({ src, name, onClick }) => {
  const { setCursor } = useContext(CursorContext);

  const handleMouseEnter = () => setCursor({ active: true, color: "red" });
  const handleMouseLeave = () => setCursor({ active: false, color: "white" });

  return (
    <L
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      <LogoImg src={src} />
      {/* <LogoCaption>{name}</LogoCaption> */}
    </L>
  );
};

function PrevCompanies() {
  const cursorHandlers = useSectionCursor({ color: colors.white });
  const navigate = useNavigate();

  const companyCursorHandlers = useCursorHandlers({
    hoverOptions: { type: "right-arrow", color: colors.red },
    defaultOptions: { type: "default", color: colors.white },
  });

  const handleCompanyClick = (company) => {
    // Navigate to the company page
    sessionStorage.setItem("scrollPosition", window.scrollY);

    navigate(`/portfolio/${company.name}`);
  };

  return (
    <SectionContainer {...cursorHandlers}>
      <TitleText>Past Companies</TitleText>
      <LogoContainer>
        {companies.map((company) => (
          <Logo
            key={company.name}
            src={company.logo}
            name={company.name}
            onClick={() => handleCompanyClick(company)}
            {...companyCursorHandlers}
          />
        ))}
      </LogoContainer>
    </SectionContainer>
  );
}

export default PrevCompanies;
