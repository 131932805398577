import { CursorContextProvider, Cursor } from "../components/general/Cursor";
import Nav from "../components/general/Nav";
import Hero from "../components/joinus/Hero";
import JoinUs from "../components/joinus/JoinUs"

function Join() {
  // 👇️ redirect to external URL
  // window.location.replace('https://join.slack.com/t/tx-momentum/shared_invite/zt-1vx6ooddh-golkeZydGNRjdXcZeVKDxg');

  return (
    <CursorContextProvider>
        <Cursor />
        <Hero />
        <JoinUs />
        <Nav color={0} home={1}/>
    </CursorContextProvider>
  )
}

export default Join;