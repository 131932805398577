import styled from "styled-components";
import { AiFillInstagram, AiFillLinkedin, AiFillMail, AiFillSlackSquare } from "react-icons/ai";
import { colors } from "../../assets/colors";
import { Link } from "react-router-dom";
import { useState } from "react";

const ButtonContainer = styled.div`
  & {
    z-index: 99;
    height: 3.5vmax;
    width: 4vmax;
    background-color: transparent;

    display: flex;
    padding-left: 0.45vmax;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 2.5vmax;
    overflow: hidden;
    @media screen and (max-width: 500px) {
      height: 6vmax;
      width: 5vmax;
      font-size: 3.5vmax;
      justify-content: center;
    }
  }
`;


const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const SocialsLink = styled.a`
  transition: all 0.2s;
  color: ${(props) => props.color || colors.black};
  &:hover {
    color: ${colors.red};
  }
`;

// TODO fix socials links
const socials = {
    "Slack": { "link": "https://join.slack.com/t/tx-momentum/shared_invite/zt-1vx6ooddh-golkeZydGNRjdXcZeVKDxg", "icon":  AiFillSlackSquare },
    "Instagram": { "link": "https://www.instagram.com/txmomentum/", "icon": AiFillInstagram },
    "LinkedIn": { "link": "https://www.linkedin.com/company/txmomentum", "icon": AiFillLinkedin },
    "Email": { "link": "mailto:team@txmomentum.org", "icon": AiFillMail }
}

function SocialButton(props) {
  return (
    <SocialsLink href={socials[props.name]["link"]} target="_blank" color={props.color ? props.color : colors.black}>
      <ButtonContainer
        style={{
          display: props.visible ? "none" : "flex",
          backgroundColor: "transparent"
        }}
        {...props}
      >
        <IconContainer>
        {socials[props.name]["icon"]()}
        </IconContainer>
      </ButtonContainer>
    </SocialsLink>
  );
}

export default SocialButton;