const red = "#F62D0B";
const black = "#141414";
const white = "#FFFFFF";
const gray = "#DDDDDD";
const darkGray = "#2F2F2F";
const darkGrayText = "#4A4A4A";

export const colors = {
    red,
    black,
    white,
    gray,
    darkGray,
    darkGrayText
}