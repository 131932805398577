import styled from "styled-components";
import NextSection from "../general/NextSection";
import { colors } from "../../assets/colors";
import { useSectionCursor } from "../general/Cursor";

const SectionContainer = styled.div`
  width: 100vw;
  height: 150vh;
  background-color: ${colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  position: sticky;
  flex-wrap: wrap;
  top: -1px;
  max-width: 100%;
`;

const TextContainer = styled.div`
  margin-left: 20vw;
  margin-right: 20vw;
`;

const TitleText = styled.h1`
  display: inline-block;
  font-size: 4vmin;
  font-family: ReadexPro, sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 700;
  text-align: left;
  color: ${colors.red};
  @media only screen and (max-width: 700px) {
    font-size: 5vmin;
  }
`;

const BodyText = styled.p`
  display: inline-block;
  font-size: 3.2vmin;
  font-family: ReadexPro, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  color: ${colors.white};
  line-height: 1.6;
  font-weight: 300;
  @media only screen and (max-width: 700px) {
    font-size: 4vmin;
  }
`;

const Spacer = styled.div`
  width: 100%;
  height: 50vh;
`;

const SmallSpacer = styled.div`
  width: 100%;
  height: 5vh;
`;

function Text() {
  const cursorHandlers = useSectionCursor({color: colors.white});
  
  return (
    <SectionContainer {...cursorHandlers}>
      {/* <NextSection ScrollNext={175} ScrollAlready={150} color={colors.white} /> */}
      <TextContainer>
        <TitleText>UT AUSTIN'S PREMIER STUDENT-LED STARTUP ACCELERATOR</TitleText>
        <SmallSpacer />
        <BodyText>
            We're bringing the campus's top engineering and product talent 
            to the hands of UT's fastest-growing student startups. Engineers 
            <b>&nbsp;get hands-on learning and opportunities</b> to add to their portfolio, 
            and startups get a helping hand to <b>build their next big thing.&nbsp;</b>  
            Above all, we aim to build a vibrant community in which you could 
            find your next mentor, co-founder, best friend, or job offer.
        </BodyText>
      </TextContainer>
      <Spacer />
    </SectionContainer>
  );
}

export default Text;