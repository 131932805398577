import { useState, useEffect, createContext, useContext, useCallback } from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { AiOutlineArrowRight } from 'react-icons/ai';

const useMousePosition = () => {
    const [position, setPosition] = useState({ clientX: 0, clientY: 0, offscreen: true });
    const updatePosition = ev => setPosition({ clientX: ev.clientX, clientY: ev.clientY, offscreen: false });
    const hide = () => setPosition(prev => ({ ...prev, offscreen: true }));
    
    useEffect(() => {
        document.addEventListener('mousemove', updatePosition, false);
        document.addEventListener('mouseenter', updatePosition, false);
        document.addEventListener('mouseleave', hide, false);
        
        return () => {
            document.removeEventListener('mousemove', updatePosition);
            document.removeEventListener('mouseenter', updatePosition);
        }
    }, []);
    
    return position;
}

export const CursorContext = createContext();

// changed for initial state
export const CursorContextProvider = ({ children, initialCursor }) => {
    const [cursor, setCursor] = useState(initialCursor || { active: false, color: colors.black, type: 'default' });

    return (
        <CursorContext.Provider value={{ cursor, setCursor }}>
            {children}
        </CursorContext.Provider>
    );
};


const CursorContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
    pointer-events: none;
    @media only screen and (max-width: 700px) {
        display: none;
    }
`;

const CursorCircle = styled.svg.attrs(props => ({
    style: {
        left: props.clientX,
        top: props.clientY,
        width: props.dims,
        height: props.dims,
        visibility: props.hidden ? 'hidden' : 'visible',
        transform: `translate(-50%, -50%) scale(${props.cursorActive ? 1.5 : 1})`,
        fill: props.cursorActive ? 'none' : props.cursorColor || colors.black,
        stroke: props.cursorActive ? props.cursorColor || colors.black : 'none',
        strokeWidth: 2,
    },
}))`
    position: absolute;
    transition: transform 0.2s ease-out, fill 0.2s ease-out;
`;

const CursorText = styled.span.attrs(props => ({
    style: {
        left: props.clientX,
        top: props.clientY,
        visibility: props.hidden ? 'hidden' : 'visible',
        color: props.cursorColor || colors.black,
        },
}))`
    position: absolute;
    font-family: ReadexPro, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    transform: translate(-50%, -150%);
`;

const CursorIcon = styled.p.attrs(props => ({
    style: {
        left: props.clientX,
        top: props.clientY,
        visibility: props.hidden ? 'hidden' : 'visible',
        color: props.cursorColor || colors.black,
        },
}))`
    position: absolute;
    font-size: 2rem;
    padding: 0;
    margin: 0;
    transform: translate(-50%, -50%);
`;

export const Cursor = () => {
    const { clientX, clientY, offscreen } = useMousePosition();
    const cursor = useContext(CursorContext).cursor;

    if (cursor.type === 'next-section') return (
        <CursorContainer>
            <CursorText
                clientX={clientX}
                clientY={clientY}
                hidden={offscreen}
                cursorColor={cursor.color}
            >
                NEXT
            </CursorText>
        </CursorContainer>
    )

    if (cursor.type === 'right-arrow') return (
        <CursorContainer>
            <CursorIcon
                clientX={clientX}
                clientY={clientY}
                hidden={offscreen}
                cursorColor={cursor.color}
            >
                <AiOutlineArrowRight/>
            </CursorIcon>
        </CursorContainer>
    )

    return (
        <CursorContainer>
            <CursorCircle
                clientX={clientX}
                clientY={clientY}
                dims={50}
                hidden={offscreen}
                cursorActive={cursor.active}
                cursorColor={cursor.color}
                viewBox="0 0 50 50"
            >
                <circle cx="25" cy="25" r="10"/>
            </CursorCircle>
        </CursorContainer>
    )
}

export const useCursorHandlers = (options = {}) => {
    const x = useContext(CursorContext);
    // console.log(x);

    const toggleCursor = (op) => x.setCursor(prev => ({...prev, ...op, active: !prev.active}));

    const onMouseEnter = useCallback(event => {
        if (options.onMouseEnter) options.onMouseEnter(event);
        toggleCursor(options.hoverOptions);
    });

    const onMouseLeave = useCallback(event => {
        if (options.onMouseLeave) options.onMouseLeave(event);
        toggleCursor(options.defaultOptions);
    });

    return {onMouseEnter, onMouseLeave};
}

export const useSectionCursor = (options = {}) => {
    const x = useContext(CursorContext);

    const onMouseEnter = useCallback(event => {
        if (options.onMouseEnter) options.onMouseEnter(event);
        x.setCursor(prev => ({...prev, color: options.color, active: false}));
    })

    return {onMouseEnter};
}

export const useNavCursor = (options = {}) => {
    const x = useContext(CursorContext);

    const onMouseEnter = useCallback(event => {
        if (options.onMouseEnter) options.onMouseEnter(event);
        x.setCursor(prev => ({...prev, color: options.color, active: false}));
    })

    const onMouseLeave = useCallback(event => {
        if (options.onMouseLeave) options.onMouseLeave(event);
        x.setCursor(prev => ({...prev, active: false}));
    })

    return {onMouseEnter, onMouseLeave};
}