import React from "react";
import styled from "styled-components";
import { BsChevronDoubleDown, BsChevronBarDown } from "react-icons/bs";
import { useCursorHandlers } from "./Cursor";
import { Link } from "react-router-dom";


const IconContainer = styled.a``;

const NextContainer = styled.div`
    position: absolute;
    top: 90vh;
    width: 100vw;
    height: 10vh;
    // right: 48.5vw; //custom fix
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    max-width: 100%;
    z-index: 5;
  `;

function NextPage(props) {

  const cursorHandlers = useCursorHandlers({
    "hoverOptions": {type: "next-section", color: props.color},
    "defaultOptions": {type: "default", color: props.color},
  });

  return (
    
      <NextContainer {...cursorHandlers}>
        <Link to={props.link} style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              height: "100%" }}>
          <BsChevronBarDown size={40} color={props.color} />
          </Link>
      </NextContainer>
    
  )
}

export default NextPage;