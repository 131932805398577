import styled from "styled-components";
import { colors } from "../../assets/colors";
import { useCursorHandlers } from "../general/Cursor";

// TODO add company semester and year to the info box
// TODO add founder titles to the info box
// TODO create arrow cursor hover option

const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5vw 5vw 5vw 0;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  @media only screen and (max-width: 550px) {
    width: 90%;
    padding: 5vw 0 10vw 5vw;
  }
`;

const StickyBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: sticky;
  top: 2em;
`;

const Name = styled.h3`
  font-family: "ReadexPro", sans-serif;
  color: ${colors.white};
  font-size: 1.5em;
  margin-bottom: 1em;
  font-weight: 700;
  @media only screen and (max-width: 700px) {
    font-size: 1.2em;
  }
`;

const Description = styled.p`
  font-family: "ReadexPro", sans-serif;
  color: ${colors.white};
  font-size: 1em;
  margin-bottom: 1em;
  font-weight: 300;
  @media only screen and (max-width: 700px) {
    font-size: 0.8em;
  }
`;

const Founders = styled.div`
  font-family: "ReadexPro", sans-serif;
  color: ${colors.white};
  font-size: 1em;
  margin-bottom: 0.5em;
  display: flex;
  font-weight: 200;
`;

const Founder = styled.a`
  font-family: "ReadexPro", sans-serif;
  color: ${colors.white};
  font-size: 0.8em;
  margin-right: 0.5em;
  &:hover {
    color: ${colors.red};
  }
  transition: all 0.5s ease-in-out;
  margin-right: 1em;
`;

export default function Company({ name, description, founders }) {
  const cursorHandlers = useCursorHandlers({"hoverOptions": {"color": colors.red}, "defaultOptions": {"color": colors.white}});
  
  let foundersComponent = ""

  if (founders != null && founders.length > 0) {
    foundersComponent = 
    
    <Founders>
      {founders.map((founder) => (
        <Founder href={founder.linkedin}>{founder.name}</Founder>
      ))}
    </Founders>
  }
  
  return (
    <CompanyInfo id="companyInfo">
      <StickyBox>
        <Name>{name}</Name>
        <Description>{description}</Description>
        {foundersComponent}
      </StickyBox>
    </CompanyInfo>
  );
};