import styled from "styled-components";
import NextSection from "../general/NextSection";
import { colors } from "../../assets/colors";
import { useSectionCursor } from "../general/Cursor";

const SectionContainer = styled.div`
  width: 100vw;
  height: 150vh;
  background-color: ${colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  position: sticky;
  flex-wrap: wrap;
  top: -1px;
  max-width: 100%;
`;

const TextContainer = styled.div`
  margin-left: 20vw;
  margin-right: 20vw;
`;

const TitleText = styled.h1`
  display: inline-block;
  font-size: 3vmin;
  font-family: ReadexPro, sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 500;
  text-align: left;
  color: ${colors.red};
  @media only screen and (max-width: 700px) {
    font-size: 5vmin;
  }
`;

const BodyText = styled.p`
  display: inline-block;
  font-size: 3.2vmin;
  font-family: ReadexPro, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  color: ${colors.white};
  line-height: 1.6;
  font-weight: 300;
  @media only screen and (max-width: 700px) {
    font-size: 4vmin;
  }
`;

const Spacer = styled.div`
  width: 100%;
  height: 50vh;
`;

const SmallSpacer = styled.div`
  width: 100%;
  height: 5vh;
`;

function Text() {
  const cursorHandlers = useSectionCursor({color: colors.white});
  
  return (
    <SectionContainer {...cursorHandlers}>
      {/* <NextSection ScrollNext={150} ScrollAlready={150} color={colors.white} /> */}
      <TextContainer>
        <TitleText>Insanely talented founding teams + robust technical products = success</TitleText>
        <SmallSpacer />
        <BodyText>
            Our startups have gone on to raise capital, secure funding from UT and Austin's
            top startup funds, join prestigious external accelerators, and more. We invest in founders and teams with
            exceptional potential to create real impact. As an integral part of UT's startup ecosystem,
            <b>&nbsp;we believe in making an exception for greatness.</b>
        </BodyText>
      </TextContainer>
      <Spacer />
    </SectionContainer>
  );
}

export default Text;