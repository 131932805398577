import Nav from "../components/general/Nav";
import Hero from "../components/about/Hero";
import Info from "../components/about/Info";
import Mission from "../components/about/Mission";
import ToPortfolio from "../components/about/ToPortfolio";
import MoreInfo from "../components/about/MoreInfo"
import { CursorContextProvider, Cursor } from "../components/general/Cursor";

function About() {
    return (
        <CursorContextProvider>
            <Cursor />
            <Hero />
            <Info />
            <Mission />
            <MoreInfo />
            <ToPortfolio />
            <Nav color={0} home={1}/>
        </CursorContextProvider>
    )
}

export default About;