import Payment from "../components/stripe/Payment";
import { useState } from "react";
import StripeStyle from "../components/stripe/StripeStyle.css"
const Stripe = () => {
  const [amount, setAmount] = useState(1000);
  return (
    <div>
      <label>
        Payment amount:
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(parseInt(e.target.value))}
        />
      </label>
      <Payment amount={amount} />
    </div>
  ); 
};

export default Stripe;
