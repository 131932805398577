import styled from "styled-components";
import { colors } from "../../assets/colors";
import starship from "../../assets/starship.mp4";

import { useSectionCursor } from "../general/Cursor";

const SectionContainer = styled.div`
  width: 100vw;
  height: 150vh;
  background-color: ${colors.black};
  display: flex;
  justify-content: top;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  position: sticky;
  flex-wrap: wrap;
  top: -1px;
  max-width: 100%;
`;

const VideoContainer = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: -1;
  position: relative;
  top: -17%;
`;

const Video = styled.video`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: 0;
`;

const TextContainer = styled.div`
  background-color: transparent;
  margin: 0;
  padding: 0;
  padding-right: 20%;
  overflow: hidden;
  position: absolute;
  left: 20%;
  top: 40%;
  z-index: 2;
`;

const Text = styled.h1`
  font-size: 5.5vmin;
  font-family: ReadexPro, sans-serif;
  margin: 0;
  padding: 0;
  color: transparent;
  font-weight: 500;
  line-height: 120%;
  color: ${colors.white};
  @media screen and (max-width: 500px) {
      font-size: 6.5vmin;
      line-height: 125%;
    }
`;

const TextBold = styled(Text)`
    display: inline;
    font-weight: 700;
    color: ${colors.red};
`;

function Mission() {
    const cursorHandlers = useSectionCursor({color: colors.red});

    return (
        <SectionContainer {...cursorHandlers}>
            <VideoContainer>
                {/* <NextSection ScrollNext={250} ScrollAlready={200} color={colors.red} /> */}
                  <Video autoPlay playsInline loop muted webkit-playsinline>
                      <source src={starship} type="video/mp4" />
                  </Video>
                <TextContainer>
                    <Text>
                      WE'RE ON A <TextBold>MISSION</TextBold> TO INSPIRE AND SUPPORT 
                      THE NEXT GENERATION OF CREATORS, INNOVATORS, AND BUILDERS.
                    </Text>
                </TextContainer>
            </VideoContainer>
        </SectionContainer>
    );
}

export default Mission;