import styled from "styled-components";
import { colors } from "../../assets/colors";
import {
  useSectionCursor,
  useCursorHandlers,
  CursorContext,
} from "../general/Cursor";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import currentPortfolioInfo from "../../assets/currentPortfolioInfo";

const SectionContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  position: sticky;
  flex-wrap: wrap;
  top: -1px;
  max-width: 100%;
`;

const TitleText = styled.h1`
  display: block;
  text-align: center;
  font-size: 4vmin;
  font-family: ReadexPro, sans-serif;
  font-weight: 500;
  color: ${colors.red};
  @media only screen and (max-width: 700px) {
    font-size: 6vmin;
  }
  text-transform: uppercase;
`;

const LogoContainer = styled.div`
  display: flex;
  font-size: 3.2vmin;
  font-family: ReadexPro, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  color: ${colors.black};
  line-height: 1.6;
  font-weight: 300;
  @media only screen and (max-width: 700px) {
    font-size: 4vmin;
  }
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const LogoImg = styled.img`
  width: 20vw;
  height: 10vw;
  margin: 1vw;
  margin-top: 4vh;
  object-fit: contain;
  @media only screen and (max-width: 700px) {
    width: 40vw;
  }
`;

const LogoCaption = styled.div`
  font-size: 2vmin;
  margin: 0;
  padding: 0;
  font-weight: 400;
  text-align: center;
`;

const L = styled.div`
  border-radius: 10px;
  &:hover {
    color: ${colors.red};
    background-color: ${colors.gray};
  }
  transition: all 0.3s;
  margin: 1px;
`;

const Logo = ({ src, name, onClick }) => {
  const { setCursor } = useContext(CursorContext);

  const handleMouseEnter = () => setCursor({ active: true, color: "red" });
  const handleMouseLeave = () => setCursor({ active: false, color: "black" });

  return (
    <L onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={onClick}
      style={{
        "&:hover": {
          color: colors.red,
        }
        }}>
      <LogoImg src={src} />
      <LogoCaption>{name}</LogoCaption>{" "}
    </L>
  );
};

function CurrentComp() {
  useEffect(() => {
    const savedPosition = sessionStorage.getItem("scrollPosition");
    if (savedPosition !== null) {
      window.scrollTo({
        top: parseInt(savedPosition, 10),
        behavior: "instant",
      });
      sessionStorage.removeItem("scrollPosition"); // Clear the saved position
    }
  }, []);

  const cursorHandlers = useSectionCursor({ color: colors.black });
  const navigate = useNavigate();

  const companyCursorHandlers = useCursorHandlers({
    hoverOptions: { type: "default", color: colors.red },
    defaultOptions: { type: "default", color: colors.black },
  });

  const handleCompanyClick = (company) => {
    // Navigate to the company page
    sessionStorage.setItem("scrollPosition", window.scrollY);
    navigate(`/portfolio/${company.name}`);
  };

  const groupedCompanies = currentPortfolioInfo.reduce((acc, company) => {
    const { semester } = company;
    if (!acc[semester]) {
      acc[semester] = [];
    }
    acc[semester].push(company);
    return acc;
  }, {});

  return (
    <SectionContainer {...cursorHandlers}>
      <TitleText>Current Cohort (Spring 2024)</TitleText>
      {Object.entries(groupedCompanies).map(([semester, companies]) => (
        <div key={semester}>
          {/* <CompanySemester>{semester}</CompanySemester> */}
          <LogoContainer>
            {companies.map((company) => (
              <Logo
                key={company.name}
                src={require(`../../assets/logos/${company.logo}`)}
                name={company.name}
                onClick={() => handleCompanyClick(company)}
                // onMouseEnter={() => this.someHandler}
                // onMouseLeave={() => this.someOtherHandler}
                {...companyCursorHandlers}
              />
            ))}
          </LogoContainer>
        </div>
      ))}
    </SectionContainer>
  );
}

export default CurrentComp;

// import { useState } from "react";
// import styled from "styled-components";
// import { colors } from "../../assets/colors";
// import {
//   useSectionCursor,
//   useCursorHandlers,
//   CursorContext,
// } from "../general/Cursor";
// import { useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { useEffect } from "react";
// import currentPortfolioInfo from "../../assets/currentPortfolioInfo";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/effect-cards';

// import '../../styles.css';
// import { EffectCards } from 'swiper/modules';
// import Company from "./InfoBox";

// const SectionContainer = styled.div`
//   width: 100vw;
//   min-height: 110vh;
//   background-color: ${colors.white};
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-wrap: wrap;
//   align-content: center;
//   position: relative;
//   flex-wrap: wrap;
//   top: -1px;
//   max-width: 100%;
// `;

// const PresentCohortContainer = styled.div`
//   display: flex;
//   width: 100%;
//   justify-content: space-evenly;
//   margin: 50px;
// `;

// const TitleText = styled.h1`
//   display: block;
//   text-align: center;
//   font-size: 4vmin;
//   font-family: ReadexPro, sans-serif;
//   font-weight: 500;
//   color: ${colors.red};
//   @media only screen and (max-width: 700px) {
//     font-size: 6vmin;
//   }
//   text-transform: uppercase;
// `;


// const LogoContainer = styled.div`
//   display: flex;
//   font-size: 3.2vmin;
//   font-family: ReadexPro, Helvetica, sans-serif;
//   margin: 0;
//   padding: 0;
//   color: ${colors.black};
//   line-height: 1.6;
//   font-weight: 300;
//   @media only screen and (max-width: 700px) {
//     font-size: 4vmin;
//   }
//   flex-wrap: wrap;
//   justify-content: space-evenly;
// `;

// const LogoImg = styled.img`
//   object-fit: contain;
//   @media only screen and (max-width: 700px) {
//     width: 40vw;
//   }
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 80%;
//   height: 100%;
// `;

// const LogoCaption = styled.div`
//   font-size: 3vmin;
//   margin: 0;
//   padding: 0;
//   font-weight: 400;
//   text-align: center;
//   font-family: ReadexPro, sans-serif;
// `;

// const L = styled.div`
//  display: flex;
//  flex-direction: column;
//  width: 100%;
//  height: 100%;
//  justify-content: space-evenly;
//  align-items: center;
 
// `;

// const LogoImgContainer = styled.div`
//   border-radius: 18px;
//   background-color: #FFFFFF;
//   display: flex;
//   width: 80%;
//   height: 70%;
//   justify-content: center;
//   align-items: center;
  
// `;
// const SwiperContainer = styled.div`
//   width: 35%;
// `;

// const DescriptionContainer = styled.div`
//   width: 50%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
// `;
// const SCContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 420px;
//   background-color: rgb(240,240,240);
//   border-radius: 20px;
// `;

// const InnerSCContainer = styled.div`
//   border: 1.5px solid ${colors.white};
//   background-color: ${colors.white};
//   border-radius: 20px;
//   width: 90%;
//   height: 80%;
//   display: flex;
  

// `;

// const StartupTitle = styled.h1`
//   font-size: 4vmin;
//   font-weight: 300;
//   text-align: left;
//   font-family: ReadexPro, sans-serif;
//   color: ${colors.black};
//   margin: 25px 25px 25px 25px;
// `;

// const StartupHeader = styled.h1`
//   display: flex;
//   justify-content: center;
//   font-size: 4vmin;
//   font-weight: 400;
//   text-align: left;
//   font-family: ReadexPro, sans-serif;
//   color: ${colors.black};
// `;


// const Logo = ({ src, name, onClick}) => {
//   const { setCursor } = useContext(CursorContext);

//   const handleMouseEnter = () => setCursor({ active: true, color: "red" });
//   const handleMouseLeave = () => setCursor({ active: false, color: "black" });
//   return (
//     <L onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//       <LogoImgContainer>
//         <LogoImg src={src} />
//       </LogoImgContainer>
//       <LogoCaption >{ name }</LogoCaption>
//     </L>
//   );
// };

// const StartupContentContainer = ({ company }) => {
//   const { setCursor } = useContext(CursorContext);
//   const handleMouseEnter = () => setCursor({ active: false, color: "white" });
//   const handleMouseLeave = () => setCursor({ active: false, color: "black" });
//   console.log(company);
//   return (
//     <SCContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//       <InnerSCContainer>
//         <StartupTitle>{ company.name }</StartupTitle>
//       </InnerSCContainer>
//     </SCContainer>
//   )
// };



// function CurrentComp() {
//   const groupedCompanies = currentPortfolioInfo.reduce((acc, company) => {
//     const { semester } = company;
//     if (!acc[semester]) {
//       acc[semester] = [];
//     }
//     acc[semester].push(company);
//     return acc;
//   }, {});

//   const companies = Object.entries(groupedCompanies)[0][1]
//   const [company, setCompany] = useState(companies[0]);

//   useEffect(() => {
//     const savedPosition = sessionStorage.getItem("scrollPosition");
//     if (savedPosition !== null) {
//       window.scrollTo({
//         top: parseInt(savedPosition, 10),
//         behavior: "instant",
//       });
//       sessionStorage.removeItem("scrollPosition"); // Clear the saved position
//     }
//   }, []);

//   const cursorHandlers = useSectionCursor({ color: colors.black });
//   const navigate = useNavigate();

//   const companyCursorHandlers = useCursorHandlers({
//     hoverOptions: { type: "default", color: colors.red },
//     defaultOptions: { type: "default", color: colors.black },
//   });

//   const handleCompanyClick = (company) => {
//     // Navigate to the company page
//     sessionStorage.setItem("scrollPosition", window.scrollY);
//     navigate(`/portfolio/${company.name}`);
//   };

//   const handleSlideChange = (swiper) => {
//     const currentIndex = swiper.activeIndex;
//     const currentCompany = companies[currentIndex];
//     setCompany(currentCompany);
//   };

//   // console.log(Object.entries(groupedCompanies)[0][1].length)
//   // const startColor = [255,255,255];
//   // const endColor = [151,154,155];
//   // const totalSlides = Object.entries(groupedCompanies)[0][1].length;

//   // const generateColor = (index, total) => {
//   //   const ratio = index / total;
//   //   const color = startColor.map((start, i) => Math.floor(start + ratio * (endColor[i] - start)));
//   //   console.log(color);
//   //   return `rgb(${color.join(',')})`;
//   // };
  
//   // const generateAndInjectStyles = (total) => {
//   //   let styleSheet = document.createElement("style");
  
//   //   for (let i = 1; i <= total; i++) {
//   //     const color = generateColor(i, total);
//   //     styleSheet.innerText += `.swiper-slide:nth-child(${i}n) { background-color: ${color}; }\n`;
//   //   }
  
//   //   document.head.appendChild(styleSheet);
//   // };
//   // generateAndInjectStyles(totalSlides);
//   return (
//     <SectionContainer {...cursorHandlers}>
//       <TitleText>Spring 2024 Cohort</TitleText>
//       {Object.entries(groupedCompanies).map(([semester, companies]) => (
//         <PresentCohortContainer key={semester}>
//           <SwiperContainer>
//             <Swiper
//               effect={'cards'}
//               grabCursor={true}
//               spaceBetween={30}
//               modules={[EffectCards]}
//               className="mySwiper"
//               onSlideChange={handleSlideChange}

//             >
//             {companies.map((company) => {
//               return (
//               <SwiperSlide key={company.name}>
//                 <Logo
//                   key={company.name}
//                   src={require(`../../assets/logos/${company.logo}`)}
//                   name={company.name}
//                   {...companyCursorHandlers}
//                 />
//               </SwiperSlide>
//             )})}
//             </Swiper>
//           </SwiperContainer>
//           <DescriptionContainer>
//             <StartupContentContainer company={company} />
//           </DescriptionContainer>
//           {/* <CompanySemester>{semester}</CompanySemester> */}
//           {/* <LogoContainer>

//             {companies.map((company) => (
//               <Logo
//                 key={company.name}
//                 src={require(`../../assets/logos/${company.logo}`)}
//                 name={company.name}
//                 onClick={() => handleCompanyClick(company)}
//                 // onMouseEnter={() => this.someHandler}
//                 // onMouseLeave={() => this.someOtherHandler}
//                 {...companyCursorHandlers}
//               />
//             ))}
//           </LogoContainer> */}
//         </PresentCohortContainer>
//       ))}
//     </SectionContainer>
//   );
// }

// export default CurrentComp;
