import tipx from './logos/tipx.png';
import tuktuk from './logos/tuktuk.png';
import cornerstone from './logos/cornerstone.png';
import root from './logos/root.png';
import alois from './logos/alois.png';
import gazelle from './logos/gazelle.png';
import populus from './logos/populus.png';
import scraps from './logos/scraps.png';
import cacao from './logos/cacao.png';
import locol from './logos/locol.png';
import sensibed from './logos/sensibed.png';
import flightdeck from './logos/flightdeck.png';

// TODO missing Three0
// TODO add founders for recent cohort

const currentPortfolioInfo = [
      {
        name: "Scraps",
        logo: "scraps.png",
        description: 'Scraps is a marketplace for artists to buy and sell music production assets.',
      },
      {
        name: "cacao.tax",
        logo: "cacao.png",
      },
      {
        name: "Rytestart",
        logo: "rytestart.png",
      },
      {
        name: "Omnisense Surgical",
        logo: "omnisense.png",
      },
      {
        name: "Thrive Education",
        logo: "thrive.png",
      },
      {
        name: "MemARy Labs",
        logo: "memary.png",
      },
      {
        name: "Active Prophylaxis",
        logo: "activeprophylaxis.png",
      }
]

export default currentPortfolioInfo;