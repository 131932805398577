import styled from "styled-components";
import NextSection from "../general/NextSection";
import { colors } from "../../assets/colors";
import { useSectionCursor } from "../general/Cursor";

const SectionContainer = styled.div`
  width: 100vw;
  height: 150vh;
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  position: sticky;
  flex-wrap: wrap;
  top: -1px;
  max-width: 100%;
`;

const TextContainer = styled.div`
  margin-left: 20vw;
  margin-right: 20vw;
`;

const TitleText = styled.h1`
  display: inline-block;
  font-size: 3vmin;
  font-family: ReadexPro, sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 500;
  text-align: left;
  color: ${colors.red};
  @media only screen and (max-width: 700px) {
    font-size: 5vmin;
  }
`;

const BodyText = styled.p`
  display: inline-block;
  font-size: 3.2vmin;
  font-family: ReadexPro, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  color: ${colors.black};
  line-height: 1.6;
  font-weight: 300;
  @media only screen and (max-width: 700px) {
    font-size: 4vmin;
  }
`;

const Spacer = styled.div`
  width: 100%;
  height: 50vh;
`;

const SmallSpacer = styled.div`
  width: 100%;
  height: 5vh;
`;

function Text() {
  const cursorHandlers = useSectionCursor({color: colors.black});
  
  return (
    <SectionContainer {...cursorHandlers}>
      {/* <NextSection ScrollNext={200} ScrollAlready={100} color={colors.white} /> */}
      <TextContainer>
        <TitleText>The team that makes the magic happen</TitleText>
        <SmallSpacer />
        <BodyText>
            Our executive board
            hand-selects the <b>best and brightest from the UT Austin student body</b> across a variety of
            majors to ensure a well-rounded cohort. Whether it's design, product, or app
            development, we know that <b>everyone has something to bring to the table.</b> Momentum,
            at its end, is a community of people that understand the power of <b>give and take.</b>
        </BodyText>
      </TextContainer>
      <Spacer />
    </SectionContainer>
  );
}

export default Text;