import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../assets/colors";
import { useSectionCursor, useCursorHandlers } from "../general/Cursor";
import NextPage from "../general/NextPage";

const SectionContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${colors.black}};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  position: sticky;
  flex-wrap: wrap;
  top: -2px;
  max-width: 100%;
`;

const TitleTextContainer = styled.div`
  background-color: transparent;
  width: fit-content;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const TitleText = styled.h1`
  font-size: 6vmin;
  font-family: ReadexPro, sans-serif;
  margin: 0;
  padding: 0;
  -webkit-text-stroke: 0.03em ${colors.white};
  color: transparent;
  font-weight: 900;
  line-height: 82%;
`;

const TitleTextStill = styled.h1`
  font-size: 8vmin;
  font-family: ReadexPro, sans-serif;
  margin: 0;
  padding: 0;
  color: transparent;
  font-weight: 900;
  line-height: 82%;
  color: white;
`;

const LineBreak = styled.div`
  width: 100%;
  height: 2vh;
`;

const Spacer = styled.div`
  width: 100%;
  height: 0vh;
`;

// TODO change link to application

function ToApply() {
  const cursorHandlers = useSectionCursor({color: colors.white});
  const linkCursorHandlers = useCursorHandlers();
  return (
    <SectionContainer {...cursorHandlers}>
      <TitleTextContainer>
        <TitleText>NEXT:</TitleText>
      </TitleTextContainer>
      <LineBreak />
      <Link to="/join-us" style={{ textDecoration: "none" }} {...linkCursorHandlers}>
        <TitleTextContainer>
          <TitleTextStill>JOIN US</TitleTextStill>
        </TitleTextContainer>
        <Spacer />
      </Link>
      <NextPage color={colors.white} link="/join-us"/>
    </SectionContainer>
  );
}

export default ToApply;