import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../assets/colors";
import portfolioInfo from "../../assets/portfolioInfo";
import { BsChevronDoubleDown } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { CursorContextProvider, Cursor } from "../general/Cursor";
import {
  useSectionCursor,
  useCursorHandlers,
  CursorContext,
} from "../general/Cursor";

const CompanyPageContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: ${colors.white};
  font-family: "ReadexPro", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -1px;
  max-width: 100%;
  cursor: default;
`;

const CompanyDetails = styled.div`
  max-width: 800px;
  text-align: center;
  cursor: inherit;
`;

const CompanyName = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  cursor: inherit;
`;

const CompanyLogo = styled.img`
  max-width: 150px;
  margin-bottom: 1rem;
  cursor: inherit;
`;

const CompanyDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  cursor: inherit;
`;

const FoundersSection = styled.div`
  margin-top: 2rem;
  text-align: left;
  cursor: inherit;
`;

const FoundersHeading = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  cursor: inherit;
`;

const FoundersList = styled.ul`
  list-style-type: none;
  padding: 0;
  cursor: inherit;
`;

const FounderItem = styled.li`
  margin-bottom: 0.5rem;
  cursor: inherit;
`;

const FounderName = styled.span`
  font-weight: bold;
  cursor: inherit;
  margin-right: 0.4rem;
`;

const FounderTitle = styled.span`
  margin-left: 0.5rem;
  font-style: italic;
  cursor: inherit;
  margin-right: 0.4rem;
`;

const FounderLink = styled.a`
  color: ${colors.black};
  text-decoration: none;
  margin-left: 0.5rem;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`;

const CompanyPage = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const { name } = useParams();

  // Find the company details from the portfolioInfo data
  const companyDetails = portfolioInfo.find((company) => company.name === name);

  if (!companyDetails) {
    return (
      <CompanyPageContainer>
        <div style={{cursor: "inherit"}}>Company not found!</div>
      </CompanyPageContainer>
    )
  }

  return (
      <CompanyPageContainer>
        <CompanyDetails>
          {companyDetails.logo && (
            <CompanyLogo
              src={companyDetails.logo}
              alt={`${companyDetails.name} Logo`}
            />
          )}
          <CompanyName>{companyDetails.name}</CompanyName>
          <CompanyDescription>{companyDetails.description}</CompanyDescription>
          {companyDetails.founders && (
            <FoundersSection>
              <FoundersHeading>Founders:</FoundersHeading>
              <FoundersList>
                {companyDetails.founders.map((founder, index) => (
                  <FounderItem key={index}>
                    <FounderName>{founder.name}</FounderName> |
                    <FounderTitle>{founder.title}</FounderTitle> | 
                    {founder.linkedin && (
                      <FounderLink
                        href={founder.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        LinkedIn
                      </FounderLink>
                    )}
                  </FounderItem>
                ))}
              </FoundersList>
            </FoundersSection>
          )}
        </CompanyDetails>
        <Link
          onClick={handleBackClick}
          style={{
            position: "absolute",
            top: "90vh",
            width: "100vw",
            height: "10vh",
            // right: 48.5vw; //custom fix
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            maxWidth: "100%",
            zIndex: "5",
            cursor: "pointer",
          }}
        >
          <BsChevronDoubleDown size={40} color="red" style={{cursor: "pointer"}}/>
        </Link>
      </CompanyPageContainer>
  );
};

export default CompanyPage;
