import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useRef, useLayoutEffect, useState, useEffect, useCallback } from "react";
import {BsList} from "react-icons/bs"
import { colors } from "../../assets/colors";
import { useCursorHandlers, useNavCursor } from "./Cursor";

// TODO remove DropdownMenu if not needed

const LogoContainer = styled.div`
  color: ${colors.black};
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  width: auto;
  max-height: 70px;
  margin: 0;
  padding: 0;
`;

const NavContainer = styled.div`
  position: fixed;
  top: 0vw;
  left: 0vw;
  padding-left:2vw;
  padding-right:2vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 96vw;
  height:15vh;
  padding-bottom: 10vh;
  z-index:50;
  transition: transform 1s, padding-bottom 1s, background-color 1s;

`;

const NavSelectionContainer = styled.div`
  display: flex;
  height: fit-content;
  width: fit-content;
  color: #1e1e1e;
  @media (max-aspect-ratio: 650/870) {
    visibility:hidden;
    width:0;
  }
`;

const NavTextContainer = styled.div`
  margin-right: 2vw;
`;

const NavText = styled.div`
  font-family: ReadexPro, sans-serif;
  margin: 0;
  padding: 0;
  font-size: 3vmin;
  font-weight: 400;
`;

const NavTextTitle = styled.p`
    font-family: ReadexPro, sans-serif;
    margin: 0;
    padding: 0;
    font-size: 3vmin;
    font-weight: 600;
    @media screen and (max-width: 500px) {
      font-size: 5vmin;
    }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  background-color: transparent;
  @media (min-aspect-ratio: 650/870) {
    display:none;
  }
`

const DropdownLink = styled.a`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background-color: rgba(255,255,255,0.8);

  &:hover {
    background-color: rgba(255,255,255,0.8);
    cursor: pointer;
  }
`

const DropdownButton = styled.button`
  padding: 16px;
  font-size: 16px;
  border: none;
  background-color: transparent;
  color: ${colors.black};
`

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  right:0;
  min-width: 160px;
  font-family: ReadexPro, sans-serif;;
  border: 1px solid black;
  border-radius: 15px 0 15px 15px;
  overflow: hidden;
  // display: inline-block;

  ${DropdownContainer}:hover & {
    display: block;
  }
`

const NavLink = styled(Link)`
    text-decoration: none;
    color: ${colors.black};
    transition: color 0.2s ease-in-out;
    &:hover {
        color: ${colors.red};
    }
`;

const isBrowser = typeof window !== `undefined`

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = element ? element.current : document.body
  const position = target.getBoundingClientRect()

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top }
}

export function useScrollPosition(effect, deps, element, useWindow, wait) {
  const position = useRef(getScrollPosition({ useWindow }))

  let throttleTimeout = null

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow })
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout = null
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, deps)
}

function Nav(props) {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);

  const [hideOnScroll, setHideOnScroll] = useState(0);
  const [hoverNav, setHoverNav] = useState(0);

  const [y,
    setY] = useState(document.scrollingElement.scrollHeight);
  const [scrollDirection,
    setScrollDirection] = useState("you have not scrolled yet");

    const [delayHandler, setDelayHandler] = useState(null);

    const handleNavigation = useCallback((e) => {

      if (y > window.scrollY) {
        setScrollDirection(1);
        setDelayHandler(setTimeout(() => {
          setScrollDirection(0);
        }, 2000))
      } 
      else if (y < window.scrollY) {
        setScrollDirection(0);
      }
      clearTimeout(delayHandler);
      setY(window.scrollY);
    }, [y]);
  
    useEffect(() => {
  
      window.addEventListener("scroll", handleNavigation);
  
      return () => {
        window.removeEventListener("scroll", handleNavigation);
      };
    }, [handleNavigation]);


  function vh(v) {
    var h = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    return -1*(v * 100) / h;
  }

  useScrollPosition(({ currPos }) => {
    var body = document.body,
    html = document.documentElement;

    var height = Math.max( body.scrollHeight, body.offsetHeight, 
                       html.clientHeight, html.scrollHeight, html.offsetHeight );
    var pos = currPos.y
    if(vh(pos) >= 75){setHideOnScroll(1)}
    else{setHideOnScroll(0)}
  }, [hideOnScroll])

  const cursorHandlersWhite = useCursorHandlers();
  const cursorHandlersBlack = useCursorHandlers({
    "hoverOptions": {color: colors.white},
    "defaultOptions": {color: colors.black}
  })
  const sectionHandlers = useNavCursor();

  const navOptions = {
    about: {path: "/about", label: "About Us"},
    portfolio: {path: "/portfolio", label: "Our Startups"},
    team: {path: "/team", label: "Meet The Team"},
    join: {path: "/join-us", label: "Join Us"}
  }

  return (
    <NavContainer {...sectionHandlers} style={{transform: (hideOnScroll !== 1) || (hoverNav) || (scrollDirection) ? "translateY(0vh)":"translateY(-15vh)", paddingBottom: (hideOnScroll !== 1) || (hoverNav) || (scrollDirection) ? "0vh":"10vh", backgroundColor: (vh(y) < -150) && ((hoverNav) || (scrollDirection)) && props.home && document.documentElement.scrollTop ? "rgba(255,255,255,0.85)":"transparent"}} onMouseOver={()=>{setHoverNav(1)}} onMouseOut={()=>{setHoverNav(0)}}>
      <Link to="/" style={{textDecoration: "none"}} {...cursorHandlersWhite}>
        <LogoContainer >
          <LogoImage src={logo} />
          <NavTextTitle style={{
            color: props.color ? colors.white : colors.black,
            display: props.home && window.innerWidth <= 500 ? "none" : "flex" }}>Texas Momentum</NavTextTitle>
        </LogoContainer>
      </Link>
      <NavSelectionContainer
        style={{
          color: props.color ? colors.white : colors.black,
          display: props.home ? "flex" : "none",
        }}
      >
        <NavLink to={navOptions.about.path} {...cursorHandlersWhite}>
          <NavTextContainer>
            <NavText>{navOptions.about.label}</NavText>
          </NavTextContainer>
        </NavLink>
        <NavLink to={navOptions.portfolio.path} {...cursorHandlersWhite}>
          <NavTextContainer>
            <NavText>{navOptions.portfolio.label}</NavText>
          </NavTextContainer>
        </NavLink>
        <NavLink to={navOptions.team.path} {...cursorHandlersWhite}>
          <NavTextContainer>
            <NavText>{navOptions.team.label}</NavText>
          </NavTextContainer>
        </NavLink>
        <NavLink to={navOptions.join.path} {...cursorHandlersWhite}>
          <NavTextContainer>
            <NavText>{navOptions.join.label}</NavText>
          </NavTextContainer>
        </NavLink>
      </NavSelectionContainer>

      <DropdownContainer
        style={{
          color: props.color ? "white" : "#1e1e1e",
          display: props.home && window.innerWidth <= 500 ? "flex" : "none"
        }}>
        <DropdownButton>
          <BsList size={25}/>
        </DropdownButton>
        <DropdownContent>
          <Link to={navOptions.about.path} style={{ textDecoration: "none", color: "#1e1e1e" }}> 
            <DropdownLink>{navOptions.about.label}</DropdownLink>
          </Link>
          <Link to={navOptions.portfolio.path} style={{ textDecoration: "none", color: "#1e1e1e" }}> 
            <DropdownLink>{navOptions.portfolio.label}</DropdownLink>
          </Link>
          <Link to={navOptions.team.path} style={{ textDecoration: "none", color: "#1e1e1e" }}> 
            <DropdownLink>{navOptions.team.label}</DropdownLink>
          </Link>
          <Link to={navOptions.join.path} style={{ textDecoration: "none", color: "#1e1e1e" }}> 
            <DropdownLink>{navOptions.join.label}</DropdownLink>
          </Link>
        </DropdownContent>
      </DropdownContainer>

    </NavContainer>
  );
}

export default Nav;